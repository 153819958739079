import React from 'react';
import styled from 'styled-components';
import Season from './Season';
import { useSelector } from 'react-redux';
export const Series = ({ tracks, onClickPlay, seriesIndex, episodeIndex, setIndex, setSeriesIndex, currentTrack, id, from }) => {
  const { theme } = useSelector((state) => state.theme);
  console.log("Seeing Tracks", tracks);
  return (
    <Wrapper className="wrapper" theme={theme}>
      {tracks && tracks.length > 0 && (
        <div className="container">
          {tracks.map((item, index) => {
            return (
              <span key={index}>
                <Season
                  {...item}
                  index={index}
                  onClickPlay={onClickPlay}
                  seriesIndex={seriesIndex}
                  episodeIndex={episodeIndex}
                  setIndex={setIndex}
                  setSeriesIndex={setSeriesIndex}
                  currentTrack={currentTrack}
                  id={id}
                  from={from}
                />
              </span>
            );
          })}
        </div>
      )}
    </Wrapper>
  );
};
const Wrapper = styled.div`
  /* background-color: #393939; */
  box-shadow: 1px 0px 5px 2px rgba(0, 0, 0, 0.75);
  background-color: ${(props) => (props.theme === 'light' ? '#ffff' : '#393939')};
  padding: 0rem 1rem 1rem 1rem;
  max-height: 700px;
  overflow-y: scroll;
  @media (max-width: 380px) {
    padding: 0.5rem 0.5rem;
  }
  a {
    color: #fff !important;
    text-decoration: none;
  }
  /* ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #fff;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #222;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  */ .container {
    background-color: #000f1e;
    color: #fff;
  }
  .heading {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    border: 1px solid #fff;

    justify-content: space-between;
    /* background-color: #000f1e; */
    background-color: ${(props) => (props.theme === 'light' ? '#f0f0f0' : 'var(--dark-ash-contrast)')};
    gap: 0.3rem;

    .img-container {
      height: 70px;
      width: 125px;
      object-fit: cover;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .content {
    .title {
      font-weight: 500;
      font-size: 17px;
    }
    h4 {
      /* color: #fff !important; */
      font-weight: 400;
      font-size: 15px;
      text-transform: capitalize;
      margin-bottom: 0rem;
      letter-spacing: 0.4px;
    }
    @media (max-width: 380px) {
      h4 {
        font-size: 12px;
        margin-bottom: 0.2rem;
      }
    }
  }
  .icon {
    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      transition: transform 0.3s linear;
      margin-right: 8px;
      img {
        width: 18px;
      }
    }
  }
  .video-icon {
    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      transition: transform 0.3s linear;
      margin-right: 8px;
      img {
        width: 20px;
      }
      svg {
        font-size: 40px;
        color: var(--hover-color);
      }
    }
  }
  .open {
    transform: rotate(180deg);
    /* transition: transform 0.3s linear; */
  }

  .episodes {
    /* background-color: #000f1e; */
    background-color: ${(props) => (props.theme === 'light' ? '#f0f0f0' : 'var(--dark-ash-contrast)')};
    @media (max-width: 500px) {
      padding: 0.5rem;
    }
  }
  .padding {
    padding: 1rem;
  }
`;
