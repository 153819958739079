import styled from 'styled-components';
import { color, space, layout, typography } from 'styled-system';

export const WatchButton = styled.button(
  {
    appearance: 'none',
    fontFamily: 'inherit',
    fontSize: 14,
    // lineHeight: 1.36,
    fontWeight: 600,
    letterSpacing: 0.75,
    borderRadius: 4,
    border: '1px solid',
    padding: '.7em 1em',
    cursor: 'pointer',
    textDecoration: 'none',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '.7em',
    color: 'var(--button-front)',
    background: 'var(--button-bg)',
    borderColor: 'var(--button-border)',
    transition: 'all 0.3s ease',

    '&:hover': {
      background: 'var(--button-hover)',
      borderColor: 'transparent'
    },

    ' @media (min-width: 901px) and (max-width: 1366px)': {
      fontSize: 13
    },
    ' @media (max-width: 500px)': {
      letterSpacing: 1,
      fontSize: 10
    },
    '&span': {
      wordWrap: 'normal',
      width: '100%'
    },
    '& svg': {
      height: 18,
      maxWidth: 15,
      ' @media (max-width: 500px)': {
        height: 15,
        maxWidth: 12
      }
    }
  },
  layout,
  color,
  space,
  typography
);

WatchButton.displayName = 'WatchButton';
