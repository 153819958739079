//eslint-disable-next-line

// import { color_config } from './color-config';
const color = window.color_config; //['rockerzs'];
const {
  buttonBg,
  buttonBorder,
  buttonHover,
  buttonFront,
  seeAll,
  heading,
  hover,
  switchBg,
  switchfront
} = color;

export default () => {
  return `
  :root{
    --button-bg:${buttonBg};
    --button-border:${buttonBorder};
    --button-hover:${buttonHover};
    --button-front:${buttonFront};
    --se-all:${seeAll};
    --heading-color:${heading};
    --hover-color:${hover};
    --switch-bg:${switchBg};
    --switch-front:${switchfront};
    --silver:  #777777;
    --black-pearl:#000F1E;
    --black: #000000;
    --off-black:rgba(0, 0, 0, .7);
    --pink-red: #f70060;
    --dark-hot-pink: #e30061;
    --reddish-pink: #ff2c52;
    --white: #ffffff;
    --off-white: #fafafa;
    --white-60: rgba(255, 255, 255, 0.6);
    --white-87: rgba(255, 255, 255, 0.87);
    --navy-blue:#141e29;
    --dark-navy-blue: #000f1e;
    --dark-navy-blue-two: #000d1a;
    --dark-navy-blue-60: rgba(0, 15, 30, 0.6);
    --dark-navy-blue-87: rgba(0, 15, 30, 0.87);
    --dark-ash: #2B2D36;
    --dark-ash-contrast: #24262D;
    --dark-ash-two: #292A33; 
    --background-primary: var(--dark-ash);
    --background-secondary: var(--black);
    --color-primary: var(--pink-red);
    --color-secondary: var(--white-87);
    --color-tertiary: var(--white-60);
    --border-primary: var(--reddish-pink);
    --font-family: Manrope;
    --font-size-base: 16px;
    --font-weight-base: normal;
    --line-height-base: 1.5;
    --body-color: var(--color-secondary);
    --body-text-align: left;
    --body-bg: var(--background-primary);
    --headings-margin-bottom: 10px;
    --paragraph-margin-bottom: 10px;
    --headings-font-family: var(--font-family);
  }
  `;
};
