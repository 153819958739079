import axios from 'axios';
import { API_URL } from 'utils/api-url.config';
import { API } from 'utils/api.config';

export async function getSubscriptionPackagesApi() {
  return await API.get(`api/packages`);
}

export async function postSubscriptionPackagesApi(data) {
  return await API.get(`api/payment/sub?${data}`);
}

export async function unsubscribeApi(data) {
  return await API.get(`api/payment/unsub?${data}`);
}
export async function unsubscribeApiSsl(data) {
  // return await API.post(`/api/payment/online/${data}/unsub`);
  return await API.post(`/payment/api/v2/pgw/${data}/unSubscription`);
}

export async function postSsl(packageType, productId) {
  return await API.get(`/api/payment/online/${packageType}/sub?productId=${productId}`);
}

export async function gpSubscriptionStatus(token) {
  return await axios.post(
    `${API_URL}ipn/v1/subscription/status`,
    {},
    {
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
  );
}
