import UserActionTypes from './user.types';

const title = window.platform_info.title;

import { takeLatest, put, all, call } from 'redux-saga/effects';
import auth, { googleProvider, facebookProvider, getCurrentUser } from 'utils/authentication';
import {
  createUserProfileDocument,
  getUserProfileDocument,
  getUserLocationDocument,
  createUserProfileDocumentCoupon,
  getOtpSuccess,
  getEmailOtpSuccess,
  setEmailLoginNewPassword,
  createUserProfileDocumentEmail
} from './user.utils';

import {
  signInSuccess,
  signInFailure,
  signOutSuccess,
  signOutFailure,
  signUpSuccess,
  signUpFailure,
  unAuthorizedUser,
  sentOTPSuccess,
  getProfileSuccess,
  getProfileFailure,
  checkUserLocationSuccess,
  checkUserLocationFailure,
  otpVerificationError,
  otpVerificationSuccess,
  emailSignInNewPasswordSuccess,
  emailSignInFailure,
  emailSignInNewPasswordFailure
} from './user.actions';
import { anonimousUser } from 'utils/api.config';
import { mixPanelAnalytics } from 'utils/MixpanelAnalytics';

export function* getSnapshotFromUserAuth(userAuth, additionalData, fromOtpBd) {
  try {
    const { token, user, refreshToken, fromOtp } = yield call(
      createUserProfileDocument,
      userAuth,
      additionalData,
      fromOtpBd
    );
    if (fromOtp && token) {
      localStorage.setItem(`otp_login_bd_${title}`, true);
    }
    mixPanelAnalytics.trackLogin(user?.site);
    localStorage.setItem(`access_token_r_${title}`, JSON.stringify(token));
    localStorage.setItem(`refresh_token_r_${title}`, JSON.stringify(refreshToken));

    localStorage.removeItem(`access_token_temp_${title}`);
    localStorage.removeItem(`refresh_token_temp_${title}`);
    localStorage.removeItem(`time_temp_${title}`);
    yield put(signInSuccess(user));
  } catch (error) {
    yield put(signInFailure(error.toString()));
  }
}

export function* getSnapshotForEmailLogin(authData, userData) {
  try {
    const userAuth = {
      ...authData,
      platform: userData?.platform
    };
    const response = yield call(createUserProfileDocumentEmail, userAuth);
    if (response?.token && response?.refreshToken) {
      mixPanelAnalytics.trackLogin(userData?.site);
      localStorage.setItem(`access_token_r_${title}`, JSON.stringify(response?.token));
      localStorage.setItem(`refresh_token_r_${title}`, JSON.stringify(response?.refreshToken));
      localStorage.setItem(`email_based_login_${title}`, JSON.stringify(userData));

      localStorage.removeItem(`access_token_temp_${title}`);
      localStorage.removeItem(`refresh_token_temp_${title}`);
      localStorage.removeItem(`time_temp_${title}`);
      yield put(signInSuccess(userData));
    } else {
      if (response?.errorStatus === 400) {
        yield put(emailSignInFailure(response.errorMessage.toString()));
      }
    }
  } catch (error) {
    yield put(emailSignInFailure(error?.response?.data?.message?.toString()));
  }
}

export function* getUserProfile() {
  try {
    const data = yield call(getUserProfileDocument);
    yield put(getProfileSuccess(data));
  } catch (error) {
    yield put(getProfileFailure(error.toString()));
  }
}
export function* getUserLocation() {
  try {
    const data = yield call(getUserLocationDocument);
    yield put(checkUserLocationSuccess(data.data, data.status));
  } catch (error) {
    yield put(checkUserLocationFailure(error.toString()));
  }
}
export function* signInWithGoogle() {
  try {
    const { user } = yield auth.signInWithPopup(googleProvider);

    yield getSnapshotFromUserAuth(user);
  } catch (error) {
    yield put(signInFailure(error.toString()));
  }
}
export function* signInWithCoupon() {
  try {
    const user = {
      id: null,
      name: 'Coupon User',
      email: '',
      mobile: '',
      avatar: ''
    };
    localStorage.setItem(`coupon_login_${title}`, true);
    yield put(signInSuccess(user));
  } catch (error) {
    yield put(signInFailure(error.toString()));
  }
}
export function* signInWithFacebook() {
  try {
    const { user } = yield auth.signInWithPopup(facebookProvider);
    yield getSnapshotFromUserAuth(user);
  } catch (error) {
    yield put(signInFailure(error.toString()));
  }
}
export function* signInWithPhoneNumber({ payload: { phoneNumber } }) {
  try {
    const appVerifier = window.recaptchaVerifier;

    const confirmationResult = yield auth.signInWithPhoneNumber(phoneNumber?.msisdn, appVerifier);
    window.confirmationResult = confirmationResult;

    yield put(sentOTPSuccess(phoneNumber));

    // yield getSnapshotFromUserAuth(user);
  } catch (error) {
    yield put(signInFailure(error.toString()));
  }
}
export function* signInWithPhoneNumberBd({ payload: { phoneNumber, details } }) {
  try {
    yield put(sentOTPSuccess(phoneNumber, details));
  } catch (error) {
    yield put(signInFailure(error.toString()));
  }
}

export function* confirmSignInWithPhoneNumber({ payload }) {
  try {
    if (payload?.details) {
      const data = {
        verification_key: payload?.details,
        otp: payload?.code,
        check: payload?.phoneNumber?.msisdn
      };

      const tempToken = JSON.parse(localStorage.getItem(`access_token_temp_${title}`));
      const headers = { Authorization: `Bearer ${tempToken}` };
      const user = yield call(getOtpSuccess, data, headers);

      if (user != '400') {
        yield put(otpVerificationSuccess());
        const userNew = {
          ...user,
          phoneNumber: user.mobile_number
        };
        const fromOtpBd = true;
        yield getSnapshotFromUserAuth(userNew, payload.phoneNumber, fromOtpBd);
      } else {
        yield put(otpVerificationError());
      }
    } else {
      // var appVerifier = window.recaptchaVerifier;
      const { user } = yield window.confirmationResult.confirm(payload.code);
      // yield put(setPhoneNumberConfirmationResult(confirmationResult));

      yield getSnapshotFromUserAuth(user, payload.phoneNumber);
    }
  } catch (error) {
    yield put(signInFailure(error.toString()));
  }
}

export function* confirmEmailOtpVerification({ payload }) {
  try {
    if (payload?.verificationKey) {
      const data = {
        verification_key: payload?.verificationKey,
        otp: payload?.code,
        check: payload?.email,
        site: payload?.site
      };

      const tempToken = JSON.parse(localStorage.getItem(`access_token_temp_${title}`));
      const headers = { Authorization: `Bearer ${tempToken}` };
      const user = yield call(getEmailOtpSuccess, data, headers);
      if (user != '400') {
        yield put(otpVerificationSuccess(user));
      } else {
        yield put(otpVerificationError());
      }
    }
  } catch (error) {
    yield put(otpVerificationError());
  }
}

export function* emailSignInNewPassword({ payload }) {
  try {
    const tempToken = JSON.parse(localStorage.getItem(`access_token_temp_${title}`));
    const headers = { Authorization: `Bearer ${tempToken}` };
    const response = yield call(setEmailLoginNewPassword, payload, headers);
    if (response.Status === 'Success') {
      yield put(emailSignInNewPasswordSuccess());
    }
  } catch (error) {
    yield put(
      emailSignInNewPasswordFailure(
        error?.response?.data?.Details?.toString() ||
          error?.response?.data?.response?.Details?.toString()
      )
    );
  }
}

export function* signInWithEmail({ payload: { signInStates, user } }) {
  try {
    yield getSnapshotForEmailLogin(signInStates, user);
  } catch (error) {
    yield put(emailSignInFailure(error.toString()));
  }
}

export function* isUserAuthenticated() {
  try {
    const couponTrue = localStorage.getItem(`coupon_login_${title}`);
    const otpLoginBd = localStorage.getItem(`otp_login_bd_${title}`);
    const gpUser = localStorage.getItem(`gp_user_${title}`);
    const robiUser = localStorage.getItem(`robi_user_${title}`);
    const emailLoginUser = localStorage.getItem(`email_based_login_${title}`);

    const userAuth = yield getCurrentUser();
    const tempTokenRaw = localStorage.getItem(`access_token_temp_${title}`);
    const tempToken = tempTokenRaw ? JSON.parse(tempTokenRaw) : null;
    const tokenRaw = localStorage.getItem(`access_token_r_${title}`);
    const token = tokenRaw ? JSON.parse(tokenRaw) : null;

    console.log('Accessed in IsUserAuthenticated', userAuth);
    console.log('imprinted here 3');

    /* GP Users */
    if (gpUser && token) {
      console.log('Is Going this line first time');
      const user = JSON.parse(gpUser);
      console.log('imprinted here 4');
      yield put(signInSuccess(user));
    } else {
      /* Robi Users */
      if (robiUser && token) {
        console.log('Is Going this line first time');
        const user = JSON.parse(robiUser);
        yield put(signInSuccess(user));
      }

      if (emailLoginUser && token) {
        console.log('Is Going this line first time');
        const user = JSON.parse(emailLoginUser);
        yield put(signInSuccess(user));
      }

      if (otpLoginBd && !userAuth && !couponTrue && token) {
        console.log('Is Going this line first time');
        const user = {
          id: null,
          name: 'Otp login user',
          email: '',
          mobile: '',
          avatar: '',
          platform: window.platform_info.api_key
        };

        yield put(signInSuccess(user));

        localStorage.setItem(`otp_login_bd_${title}`);
      }

      // if user from otp login and signed in firebase anonymously
      if (otpLoginBd && userAuth?.isAnonymous) {
        console.log('Is Going this line first time');
        const user = {
          id: null,
          name: 'Otp login user',
          email: '',
          mobile: '',
          avatar: '',
          platform: window.platform_info.api_key
        };
        console.log('OtpLogin Bd && UserAuth anonymous', userAuth);
        yield put(signInSuccess(user));
      }

      if (!userAuth && !couponTrue) {
        console.log('Is Going this line first time');
        if (!tempToken && !token) {
          anonimousUser();
        }
        yield put(unAuthorizedUser());
        return;
      }
      if (couponTrue && !userAuth && !otpLoginBd) {
        yield signInWithCoupon();
      }

      // if coupon user and signed in firebase anonymously
      if (couponTrue && userAuth?.isAnonymous) {
        yield signInWithCoupon();
      }
      if (userAuth && token) {
        console.log('Is Going this line first time');
        const {
          uid,
          photoURL,
          displayName,
          email,
          phoneNumber,
          providerId
        } = userAuth.providerData[0];

        const user = {
          id: uid,
          name: displayName,
          email,
          mobile: phoneNumber,
          avatar: photoURL,
          mobile_number: null,
          msisdn: null,
          country_code: null,
          site: providerId.replace('.com', ''),
          platform: window.platform_info.api_key
        };
        yield put(signInSuccess(user));
      }
      if (userAuth && !token) {
        console.log('Is Going this line first time');
        console.log('Email User & Token', userAuth);
        yield getSnapshotFromUserAuth(userAuth);
      }
    }
  } catch (error) {
    console.log('Is Going this line first time', error);
    yield put(signInFailure(error.toString()));
  }
}
export function* signOut() {
  try {
    yield auth.signOut();
    yield put(signOutSuccess());
  } catch (error) {
    yield put(signOutFailure(error.toString()));
  }
}

export function* signUp({ payload: { email, password, confirmPassword } }) {
  try {
    const { user } = yield auth.createUserWithEmailAndPassword(email, password);
    yield put(signUpSuccess({ user, additionalData: { displayName } }));
  } catch (error) {
    yield put(signUpFailure(error.toString()));
  }
}
export function* signInAfterSignUp({ payload: { user, additionalData } }) {
  yield getSnapshotFromUserAuth(user, additionalData);
}
export function* onGoogleSignInStart() {
  yield takeLatest(UserActionTypes.GOOGLE_SIGN_IN_START, signInWithGoogle);
}
export function* onCouponSignInStart() {
  yield takeLatest(UserActionTypes.COUPON_SIGN_IN_START, signInWithCoupon);
}
export function* onFacebookSignInStart() {
  yield takeLatest(UserActionTypes.FACEBOOK_SIGN_IN_START, signInWithFacebook);
}
export function* onPhoneNumberSignInStart() {
  yield takeLatest(UserActionTypes.PHONE_NUMBER_SIGN_IN_START, signInWithPhoneNumber);
}
export function* onPhoneNumberSignInStartBd() {
  yield takeLatest(UserActionTypes.PHONE_NUMBER_SIGN_IN_START_BD, signInWithPhoneNumberBd);
}

export function* onEmailSignInStart() {
  yield takeLatest(UserActionTypes.EMAIL_SIGN_IN_START, signInWithEmail);
}
export function* onPhoneConfirmationStart() {
  yield takeLatest(
    UserActionTypes.PHONE_NUMBER_VERIFICATION_CONFIRMATION_START,
    confirmSignInWithPhoneNumber
  );
}
export function* onForgotPassConfirmationStart() {
  yield takeLatest(
    UserActionTypes.EMAIL_FORGOT_PASSWORD_VERIFICATION_CONFIRMATION_START,
    confirmEmailOtpVerification
  );
}
export function* onEmailSignInNewPassword() {
  yield takeLatest(UserActionTypes.EMAIL_SIGN_IN_NEW_PASSWORD_START, emailSignInNewPassword);
}
export function* onCheckUserSession() {
  yield takeLatest(UserActionTypes.CHECK_USER_SESSION, isUserAuthenticated);
}

export function* onSignOutStart() {
  yield takeLatest(UserActionTypes.SIGN_OUT_START, signOut);
}
export function* onSignUpStart() {
  yield takeLatest(UserActionTypes.SIGN_UP_START, signUp);
}
export function* onSignUpSuccess() {
  yield takeLatest(UserActionTypes.SIGN_UP_SUCCESS, signInAfterSignUp);
}
export function* onUserProfileGet() {
  yield takeLatest(UserActionTypes.GET_PROFILE, getUserProfile);
}
export function* onUserLocationGet() {
  yield takeLatest(UserActionTypes.GET_USER_LOCATION, getUserLocation);
}
export function* userSagas() {
  yield all([
    call(onCouponSignInStart),
    call(onGoogleSignInStart),
    call(onFacebookSignInStart),
    call(onPhoneNumberSignInStart),
    call(onPhoneNumberSignInStartBd),
    call(onPhoneConfirmationStart),
    call(onEmailSignInStart),
    call(onCheckUserSession),
    call(onSignOutStart),
    call(onSignUpStart),
    call(onUserProfileGet),
    call(onSignUpSuccess),
    call(onUserLocationGet),
    call(onForgotPassConfirmationStart),
    call(onEmailSignInNewPassword)
  ]);
}
