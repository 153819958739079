import styled, { css } from 'styled-components';
import { color, space, variant, layout, typography } from 'styled-system';

export const Button = styled.button(
  {
    appearance: 'none',
    fontFamily: 'inherit',
    fontSize: 14,
    // lineHeight: 1.36,
    fontWeight: 600,
    letterSpacing: 0.75,
    borderRadius: 4,
    border: '1px solid',
    padding: '11px 1rem',
    cursor: 'pointer',
    textDecoration: 'none',
    textTransform: 'uppercase',
    pointerEvents: props => props.disabled && 'none',
    opacity: props => props.disabled && '0.4',
    cursor: props => props.disabled && 'not-allowed',
    '@media (max-width: 525px)': {
      fontSize: 12,
      padding: 8,
      marginTop: 5
      // minWidth: '110px !important'
    }
  },
  layout,
  color,
  space,
  typography,
  variant({
    // scale: 'buttons',
    variants: {
      primary: {
        fontSize: [14],
        padding: '11px 1rem',
        color: 'var(--button-front)',
        bg: 'var(--button-bg)',
        borderColor: 'var(--button-border)',
        transition: '.3s ease',
        '&:hover': {
          background: 'var(--button-hover)',
          borderColor: 'transparent'
        },
        ' @media (min-width: 901px) and (max-width: 1366px)': {
          fontSize: 13
        },
      },
      secondary: {
        color: 'var(--white)',
        bg: 'var(--dark-navy-blue)'
      },
      transparent: {
        color: 'var(--white)',
        bg: 'transparent',
        borderColor: 'transparent',
        ':focus': {
          outline: 'none'
        }
      },
      facebook: {
        color: 'var(--white-87)',
        bg: '#2553b4',
        borderColor: 'transparent',
        boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.24), 0 0 1px 0 rgba(0, 0, 0, 0.12)'
      },
      google: {
        color: 'var(--dark-navy-blue-87)',
        bg: 'var(--white)',
        boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.24), 0 0 1px 0 rgba(0, 0, 0, 0.12)'
      }
    }
  }),
  variant({
    prop: 'size',
    variants: {
      large: {
        fontSize: 18,
        lineHeight: 1.06,
        letterSpacing: 0.96,
        padding: '1.5rem 1rem'
      },
      small: {
        fontSize: 1,
        lineHeight: 'body'
      }
    }
  })
);

Button.displayName = 'Button';
