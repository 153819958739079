import React from 'react';
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.error(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (<div style={{ minHeight: '350px', display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                <div>
                    <h1>Something went wrong.</h1>
                    <p >Here are some tips to help you instead:</p>
                    <ul style={{ lineHeight: 1.7 }}>
                        <li>Try reloading the app</li>
                        <li>Clearing the browser cache</li>
                        <li>Checking the network cables</li>
                    </ul>
                </div>
            </div>)
        }

        return this.props.children;
    }
}
export default ErrorBoundary;
