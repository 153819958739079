import { createGlobalStyle } from 'styled-components';
import rootVariables from './variables.styled';

export const GlobalStyles = createGlobalStyle`
*,
*::before,
*::after {
  box-sizing: border-box;
}
${rootVariables()}

body {
  margin: 0;
  font-family: var(--font-family), sans-serif, system-ui;
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-base);
  line-height: var(--line-height-base);
   color: ${({ theme }) => theme.text};
  text-align: var(--body-text-align);
  background: ${({ theme }) => theme.body};
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(var(--black), 0);
  overflow-x: hidden;
  @media (min-width: 901px) and (max-width: 1366px) {
    font-size: 15px
  }
}

.fonts-loaded body {
  font-family: var(--font-family), sans-serif, system-ui;
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-base);
  line-height: var(--line-height-base);
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: var(--headings-margin-bottom);
  font-family: var(--headings-font-family);
  color: ${({ theme }) => theme.heading};
  ${'' /* font-style: $headings-font-style; */}
  ${'' /* font-weight: $headings-font-weight; */}
  ${'' /* line-height: $headings-line-height; */}
  ${'' /* color: $headings-color; */}
}
p {
  margin-top: 0;
  margin-bottom: var(--paragraph-margin-bottom);
  color: ${({ theme }) => theme.text} ;
}
ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

.category-container-class {

  margin-left: -16px !important;

  .category-item-class:hover {
    z-index: 1 !important;
  }
}

.active {
  color: var(--dark-hot-pink);
}
.dialog-center {
              display: flex;
              align-items: center;
              justify-content: center;
            }


.rockers-phone-input {
  width: 100% !important;
  height: 56px !important;
  border-radius: 8px !important;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.24), 0 0 1px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px rgba(255, 255, 255, 0.3);
  background-color: var(--white);
}
.react-tel-input .flag-dropdown {
  border-radius: 8px 0 0 8px !important;
}
.react-tel-input .selected-flag {
  border-radius: 8px 0 0 8px !important;
}
.react-tel-input .country-list .flag {
  top: 6px !important;
}
.rockers-phone-input-dropdown {
  width: 310px !important;
  border-radius: 0 0 8px 8px !important;
  color: #000;
}
.rc-menu-horizontal {
    background-color: transparent;
    border-bottom: none;
}
.rc-menu-horizontal > .rc-menu-item, .rc-menu-horizontal > .rc-menu-submenu > .rc-menu-submenu-title {
  padding: 0 20px;
}
.rc-menu-horizontal > .rc-menu-submenu-active {
  background-color: transparent;
  border-bottom: none;
}
.rc-menu-horizontal > .rc-menu-submenu, .rc-menu-horizontal > .rc-menu-item {
  border-bottom: none;
}
.rc-menu-horizontal > .rc-menu-submenu-active, .rc-menu-horizontal > .rc-menu-item-active {
  border-bottom: none;
  background-color: transparent;
}
.rc-dialog-content {
  background-color: transparent!important;
}
.rc-dialog-body {
  padding: 0!important;
}

.rc-dialog-mask{
  background-color: rgba(0,0,0,0.9) !important;
}

.rc-dialog-close{
  color: #ffffff !important;
  text-shadow: none !important;
  opacity: 1 !important;
  font-size: 56px !important;
  font-weight: 100 !important;
  width: 80px !important;
  height: 80px !important;
  padding: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 40px !important;
  top: -120px !important;
  left: 50%;
  right: auto !important;
  margin-left: -30px;
  border: 1px solid #979797 !important;
}

.rc-dialog-close-x:after {
    content: '' !important;
    width: 40px;
    height: 4px;
    border-radius: 4px;
    background-color: #979797;
    display: block;
    transform: rotate(45deg);
    position: absolute;
    left: 50%;
    margin-left: -20px;
    top: 50%;
    margin-top: -2px;
}

.rc-dialog-close-x:before {
    content: '' !important;
    width: 40px;
    height: 4px;
    border-radius: 4px;
    background-color: #979797;
    display: block;
    transform: rotate(-45deg);
    position: absolute;
    left: 50%;
    margin-left: -20px;
    top: 50%;
    margin-top: -2px;
}

@-webkit-keyframes slide {
  100% {
    right: 0;
  }
}

@keyframes slide {
  100% {
    right: 0;
  }
}
.category-box{
  margin-bottom:20px !important;
  @media(max-width:600px){
    margin-bottom:12px !important;
  }
}

//video js resolution menu css
.vjs-menu li{
  text-transform:capitalize !important;
}
.vjs-icon-placeholder{
  text-transform:capitalize !important;
}

//videojs maximize button css
// hack: shows control bar all the time
// .vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
//   visibility: visible !important;
//   opacity: 1 !important;
//   pointer-events: none;
//   transition: visibility 1s, opacity 1s;
// }
@media (max-width: 575.98px) {
  .video-js .vjs-control-bar {
    height: 4em !important;
  }
  .video-js .vjs-time-control {
    line-height: 4em !important;
  }
  .vjs-button > .vjs-icon-placeholder:before {
    font-size: 2.2em !important;
  }
  .video-js .vjs-fullscreen-control .vjs-icon-placeholder:before {
    font-size: 2.6em !important;
    line-height: 1.45;
  }  
}
`;
