export let API_URL = '/';

const dev = {
  API_URL: 'https://dev-api.rockstreamer.com/'
};
const stage = {
  API_URL: 'https://stage-api.rockstreamer.com/'
};
const prod = {
  API_URL: 'https://api.rockstreamer.com/'
};

if (process.env.REACT_APP_STAGE === 'production') {
  API_URL = prod.API_URL;
}
else if (process.env.REACT_APP_STAGE === 'stage') {
  API_URL = stage.API_URL;
}
else {
  API_URL = dev.API_URL;
}
