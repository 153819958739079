export const callbackTypeOne = (expiryTime) => {
    // if jwt decoded token value expired then 
    //send tokenStatus callback will be false and message will be
    // 'token expired' else true and 'token valid'
    const currentTimeInSeconds = Math.round(Date.now() / 1000);
    if (expiryTime > currentTimeInSeconds) {
        console.log("Sending callback true with valid message");
        if(window && window.iscreen) {
            window.iscreen.tokenStatus(true, "token valid");
        }
        window?.webkit?.messageHandlers?.onTokenStatus?.postMessage({
            status: true,
            message: "Token valid"
        })
    } else {
        console.log('Sending callback false with expired message');
        if(window && window.iscreen) {
            window.iscreen.tokenStatus(false, "token expired");
        }
        window?.webkit?.messageHandlers?.onTokenStatus?.postMessage({
            status: false,
            message: "Token expired"
        })
    }
}

export const callbackTypeTwo = (path, isPremium) => {
    console.log("Callback type two executing", path, isPremium);
    if(window && window.iscreen) {
        window.iscreen.contentChange(path, isPremium);
    }
    window?.webkit?.messageHandlers?.onContentChange?.postMessage({contentUrl:path, isPremium: isPremium})
}

export const callbackTypeThree = ({name, params}) => {
    const values = params[0] + ',' + params[1];
    const paramsIOS = {[params[0]]: params[1]};
    console.log("Callback type three executing");
    if(window && window.iscreen) {
        // window.iscreen.eventExecute({name: name, params: params});
        window.iscreen.eventExecute(name, values);
    }
    window?.webkit?.messageHandlers?.onEventExecute?.postMessage({name, paramsIOS})
}