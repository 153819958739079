export const lightTheme = {
  body: '#fafafa',
  text: '#141E29',
  heading: '#000F1E'
};
export const darkTheme = {
  // body: '#000f1e',
  body: '#2B2D36',
  text: '#FAFAFA',
  heading: '#FAFAFA'
};
