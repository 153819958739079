import firebase from 'firebase/app';
// import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/auth';

import config from '../credentials/client';

!firebase.apps.length ? firebase.initializeApp(config) : firebase.app();
firebase.analytics();
export const auth = firebase.auth();

// export const firestore = firebase.firestore();
export default firebase;
