import React, { lazy } from 'react';
import styled from 'styled-components';
import css from '@styled-system/css';
import { useLocation } from 'react-router-dom';
const Header = lazy(() => import('./header'));
const Footers = lazy(() => import('./footerMain'));
const PlayAudio = lazy(() => import('../PlayAudio'));

export default function Layout({ children }) {
  const location = useLocation();
  const title = window.platform_info.title;
  const iosUser =
    localStorage.getItem(`ios_sub_user_${title}`) && localStorage.getItem(`ios_sub_user_${title}`);
  return (
    <RootBox>
      {iosUser
        ? ''
        : !location.pathname.startsWith('/reels') && <Header front={location.pathname == '/'} />}
      <Main front={location.pathname == '/'}>{children}</Main>
      {iosUser ? '' : <Footers />}
      <PlayAudio />
    </RootBox>
  );
}

const RootBox = styled('div')(
  css({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    minWidth: '100%',
    justifyContent: 'space-between'
  })
);

const Main = styled('main')((props) =>
  css({
    paddingBottom: 150,
    marginTop: `${props.front ? '-92px' : 0}`,
    '@media (max-width: 920px)': {
      marginTop: `${props.front ? '0px' : 0}`
    },

    zIndex: 111,
    overflow: 'hidden',
    minHeight: ['calc(100vh - 300px)', 'calc(100vh - 200px)']
  })
);
