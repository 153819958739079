import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from 'redux/store';
import App from 'App';
import { checkUserSession } from 'redux/user/user.actions';
import * as serviceWorker from 'serviceWorker';
import './i18n';
import mixpanel from 'mixpanel-browser';
import { MixpanelProvider } from 'react-mixpanel';
// const renderApp = preloadedState => {
// const store = configureStore(preloadedState);
document.title = window.platform_info.title;
const title = window?.platform_info?.title;
const storedUser = localStorage.getItem(`gp_user_${title}`);
const gpUser = storedUser ? JSON.parse(storedUser) : null;
// const isAppleUserRefreshed = localStorage.getItem('isAppleUserRefreshed' || 'false');
const isMygpUserRefreshed = localStorage.getItem('isMygpUserRefreshed' || 'false');

// Function to check if the user is on an iPhone
const isIphoneUser = () => {
  const userAgent = window.navigator.userAgent;
  console.log('User Agent', userAgent);
  return /iPhone|iPod/.test(userAgent);
};

console.log('Is My gp UserRefreshed', isMygpUserRefreshed);

// Trigger a hard refresh if the user is on an iPhone
// if (isIphoneUser() || gpUser) {
//   console.log('gp user log', gpUser);
//   console.log('Getting on Apple user');
//   if (!isAppleUserRefreshed) {
//     console.log('Apple user refresh true');
//     // localStorage.removeItem(`access_token_r_${title}`);
//     // localStorage.removeItem(`refresh_token_r_${title}`);
//     window.localStorage.clear();
//     localStorage.setItem('isAppleUserRefreshed', 'true');
//     // window.location.reload(true);
//   }
// }

const url = new URL(window.location.href);

// Retrieve the 'token' parameter
const token = url.searchParams.get('token');

console.log('query token', token); // Outputs the token value, or null if it's not present

function decodeJWT(token) {
  // Split the token into its parts (header, payload, signature)
  const [header, payload, signature] = token.split('.');

  // Decode base64 URL (replace '-' and '_' for correct base64 format)
  const decodeBase64Url = (str) => {
    let base64 = str.replace(/-/g, '+').replace(/_/g, '/');
    return decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );
  };

  // Decode header and payload
  const decodedHeader = JSON.parse(decodeBase64Url(header));
  const decodedPayload = JSON.parse(decodeBase64Url(payload));

  return { header: decodedHeader, payload: decodedPayload, signature };
}

// Example usage
if (token) {
  try {
    const decodedJWT = decodeJWT(token);
    console.log('Decoded token', decodedJWT);

    // Trigger a hard refresh if the user is on an iPhone
    if (decodedJWT.payload.partner !== null && decodedJWT.payload.partner === 'GP_MYGP') {
      console.log('Getting on Mygp user');
      // if (!isMygpUserRefreshed) {
      console.log('Mygp user refresh true');
      localStorage.clear();
      // localStorage.setItem('isMygpUserRefreshed', 'true');
      // window.location.reload(true);
      // }
    }
  } catch (error) {
    console.log('Invalid token param', error);
  }
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Suspense fallback="">
        <MixpanelProvider mixpanel={mixpanel}>
          <App />
        </MixpanelProvider>
      </Suspense>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
store.dispatch(checkUserSession());
// };
// (async () => renderApp(await store.dispatch(checkUserSession())))();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
